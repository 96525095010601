import { UsersIcon } from '@heroicons/react/24/outline'
import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import ApiResource from '@components/api-resource'
import ApiResourceEdit from '@components/api-resource/api-resource-edit'
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import Guard from '@components/guard/guard'
import Layout from '@components/layouts/layout/layout'
import Modal from '@components/modals/modal'
import SectionTitle from '@components/section-title/section-title'
import { type User } from '@interfaces/api/user'
import useApiResource from '@services/api/definition'
import { captureException } from '@services/exceptions/capture-exception'

const UsersList = () => {
  const { t: translateResource } = useTranslation('apiResources', { keyPrefix: 'users' })
  const [openEdit, setOpenEdit] = useState(false)
  const definition = useApiResource('users/{uid}')
  const [user, setUser] = useState<User>()
  const navigate = useNavigate()

  const breadcrumbs = [
    { href: '', name: translateResource('title') }
  ]

  const onEntryClick = (entry) => {
    navigate(`/users/${entry.uid}`)
  }

  const queryClient = useQueryClient()
  const onReload = () => {
    queryClient.refetchQueries({ queryKey: ['users'] }).catch(captureException)
  }

  const onEditClick = (user) => {
    setUser(user)
    setOpenEdit(true)
  }

  const onEdit = () => {
    onReload()
    setOpenEdit(false)
  }

  const beforeEdit = (entry) => {
    if (entry.disabledReason === '') {
      entry.disabledReason = null
    }

    return entry
  }

  return (
    <Layout description={translateResource('description')} title={translateResource('title')}>
      <Guard>
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <SectionTitle icon={UsersIcon} title={translateResource('title')} />

        <ApiResource createdAt={'desc'} customFieldsToDisplay={['email', 'isVerified', 'isAdmin', 'roles', 'createdAt', 'lastLoginAt']} name={'users'} onClick={onEntryClick} withEditOption={onEditClick} />

        <>
          {user && definition && (
            <Modal center open={openEdit} setOpen={setOpenEdit} size='large' title={translateResource('edit')}>
              <ApiResourceEdit beforeEdit={beforeEdit} definition={definition} id={user.uid} onEdit={onEdit} />
            </Modal>
          )}
        </>
      </Guard>
    </Layout>
  )
}

export default UsersList
