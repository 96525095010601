import { CalendarDaysIcon, HomeModernIcon, MapPinIcon } from '@heroicons/react/24/outline'
import React from 'react'
import { Link } from 'react-router-dom'

import Date from '@components/api-resource/resource-entry-fields/date/date'
import { Badge } from '@components/badge'
import { type BookingRequestInfosInterfaces } from '@components/booking-request-infos/index'

const BookingRequestInfos = ({ bookingRequest }: BookingRequestInfosInterfaces) => {
  return (
    <>
      <div className='space-y-2'>
        <div className='font-medium text-gray-900'>Date & lieu</div>

        <div>
          <div className='flex items-center space-x-1'>
            <CalendarDaysIcon aria-hidden='true' className='h-6 w-5 text-gray-400' />

            <span>Du</span>

            <span className='font-semibold'>
              <Date value={bookingRequest.checkInDate} />
            </span>

            <span>au</span>

            <span className='font-semibold'>
              <Date value={bookingRequest.checkOutDate} />
            </span>
          </div>

          <div className='flex items-center space-x-1'>
            <MapPinIcon aria-hidden='true' className='h-6 w-5 text-gray-400' />

            <span>{bookingRequest.hotel.destination.name.fr}</span>
          </div>

          <Link className='flex items-center space-x-1' to={`/hotels/${bookingRequest.hotel.uid}`}>
            <HomeModernIcon aria-hidden='true' className='h-6 w-5 text-gray-400' />

            <span>{bookingRequest.hotel.name.fr}</span>
          </Link>
        </div>
      </div>

      <div className='space-y-2'>
        <div className='font-medium text-gray-900'>Participants</div>

        <div>
          <div className='flex items-center space-x-1'>
            <Badge text={`${bookingRequest.roomCount} chambre(s)`} />

            <Badge text={`${bookingRequest.adultCount} adulte(s)`} />

            <Badge text={`${bookingRequest.childCount} enfant(s)`} />
          </div>
        </div>
      </div>

      <div className='space-y-2'>
        <div className='font-medium text-gray-900'>Chambres</div>

        <div>
          {bookingRequest.roomsPaxes.length > 0 && bookingRequest.roomsPaxes.map((room, roomIndex) => (
            <div className='flex items-center space-x-2' key={roomIndex}>
              <div className='text-xs'>{`Room ${roomIndex + 1} :`}</div>

              <div className='space-x-2'>
                {room.paxes.map((pax, paxIndex) => (
                  <Badge key={paxIndex}
                    text={`${pax.type} ${pax.age ? ` - ${pax.age} an${pax.age > 1 ? 's' : ''}` : ''}`}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className='space-y-2'>
        <div className='font-medium text-gray-900'>Commentaire client</div>

        <div>
          {bookingRequest.commentFromUser}
        </div>
      </div>
    </>
  )
}

export default BookingRequestInfos
