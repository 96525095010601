import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import { Form, Formik } from 'formik'
import { useEffect, useRef, useState } from 'react'
import * as React from 'react'
import * as Yup from 'yup'

import { Badge } from '@components/badge'
import Button from '@components/buttons/button'
import IconButton from '@components/buttons/icon-button'
import FormNumberField from '@components/form-fields/form-number-field'
import FormTextField from '@components/form-fields/form-text-field'
import { type AvailabilityRoom } from '@interfaces/api/availability'
import { type OfferCreateFormValues } from '@interfaces/api/offer'
import {
  type OfferCreateFormInterfaces
} from '@pages/booking-requests/components/offer-create-form/offer-create-form.interfaces'
import AvailabilitiesModal from '@pages/booking-requests/entry/availabilities-modal/availabilities-modal'
import { captureException } from '@services/exceptions/capture-exception'

const OfferSchema = Yup.object().shape({
  externalPrices: Yup.object().shape({
    booking: Yup.number()
      .required('Le prix Booking est requis')
      .min(0.01, 'Le prix doit être supérieur à 0'),
    expedia: Yup.number()
      .required('Le prix Expedia est requis')
      .min(0.01, 'Le prix doit être supérieur à 0')
  }),
  label: Yup.string().required('Le label est requis'),
  rooms: Yup.array().of(
    Yup.object().shape({
      rates: Yup.array().of(
        Yup.object().shape({
          margin: Yup.number()
            .required('La marge est requise')
            .min(0, 'La marge doit être supérieure ou égale à 0'),
          minimumMargin: Yup.number()
            .required('La marge minimum est requise')
            .min(0, 'La marge minimum doit être supérieure ou égale à 0'),
          sellingPrice: Yup.number()
            .required('Le prix de vente est requis')
            .test('is-greater', 'Le prix de vente doit être supérieur au prix d\'achat + marge minimum', function (value) {
              const { minimumMargin, net } = this.parent

              return value > (minimumMargin + net / 100)
            })
        })
      )
    })
  )
})

const OfferCreateForm = ({ bookingRequest, editMode = false, offer, onSubmit }: OfferCreateFormInterfaces) => {
  const emptyInitialValues = {
    externalPrices: {
      booking: 0,
      expedia: 0
    },
    label: '',
    margin: 0,
    price: 0,
    rooms: bookingRequest.roomsPaxes.map(() => ({ rates: [], room: null }))
  }
  const [initialValues, setInitialValues] = useState<OfferCreateFormValues>(emptyInitialValues)
  const [availabilitiesModalOpen, setAvailabilitiesModalOpen] = useState(false)
  const [selectedRoomNumber, setSelectedRoomNumber] = useState(1)

  const setMarginTimeout = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (editMode && offer) {
      setInitialValues({
        externalPrices: {
          booking: offer.externalPrices.booking / 100,
          expedia: offer.externalPrices.expedia / 100
        },
        label: offer.label,
        rooms: offer.rooms
      })
    } else if (bookingRequest) {
      setInitialValues(emptyInitialValues)
    }
  }, [editMode, offer, bookingRequest])

  const handleMarginChange = (setFieldValue, values, roomIndex, rateIndex) => (e) => {
    const newMargin = Number(e.target.value)
    setFieldValue(`rooms[${roomIndex}].rates[${rateIndex}].margin`, newMargin).catch(captureException)
    const newSellingPrice = Math.round((Number(values.rooms[roomIndex].rates[rateIndex].net) + Number(values.rooms[roomIndex].rates[rateIndex].net) * (newMargin / 100)))
    setFieldValue(`rooms[${roomIndex}].rates[${rateIndex}].sellingPrice`, newSellingPrice).catch(captureException)
  }

  const handleSellingPriceChange = (setFieldValue, values, roomIndex, rateIndex) => (e) => {
    const newSellingPrice = Number(e.target.value)
    setFieldValue(`rooms[${roomIndex}].rates[${rateIndex}].sellingPrice`, newSellingPrice).catch(captureException)
    if (setMarginTimeout.current !== null) {
      clearTimeout(setMarginTimeout.current)
    }
    setMarginTimeout.current = setTimeout(() => {
      const newMargin = Math.round(((Number(newSellingPrice) - Number(values.rooms[roomIndex].rates[rateIndex].net)) / Number(values.rooms[roomIndex].rates[rateIndex].net)) * 100)
      setFieldValue(`rooms[${roomIndex}].rates[${rateIndex}].margin`, newMargin).catch(captureException)
    }, 500)
  }

  const handleMinimumMarginChange = (setFieldValue, values, roomIndex, rateIndex) => (e) => {
    const newMinimumMargin = Number(e.target.value)
    setFieldValue(`rooms[${roomIndex}].rates[${rateIndex}].minimumMargin`, newMinimumMargin).catch(captureException)
  }

  const handleOpenAvailabilitiesModal = (roomNumber: number) => {
    setSelectedRoomNumber(roomNumber)
    setAvailabilitiesModalOpen(true)
  }

  const handleAddRate = (rate, room: AvailabilityRoom, setFieldValue, values) => {
    const newRooms = [...values.rooms]
    const selectedRoom = newRooms[selectedRoomNumber - 1]

    // Ajouter le nouveau rate à la liste des rates existants de la room sélectionnée
    const updatedRates = [...selectedRoom.rates, { ...rate }]
    newRooms[selectedRoomNumber - 1] = { ...selectedRoom, rates: updatedRates, room }

    // Mettre à jour les valeurs du formulaire
    setFieldValue('rooms', newRooms).catch(captureException)

    // Mettre à jour le selling price pour le nouveau rate ajouté
    const rateIndex = updatedRates.length - 1
    const newSellingPrice = Math.round(rate.net + rate.net * (rate.margin / 100))
    setFieldValue(`rooms[${selectedRoomNumber - 1}].rates[${rateIndex}].sellingPrice`, newSellingPrice).catch(captureException)
  }

  const handleRemoveRoom = (roomIndex, setFieldValue, values) => {
    const updatedRooms = [...values.rooms]
    updatedRooms[roomIndex].room = null
    updatedRooms[roomIndex].rates = []
    setFieldValue('rooms', updatedRooms).catch(captureException)
  }

  const handleRemoveRate = (roomIndex, rateIndex, setFieldValue, values) => {
    const updatedRooms = [...values.rooms]
    updatedRooms[roomIndex].rates = updatedRooms[roomIndex].rates.filter((_, index) => index !== rateIndex)
    setFieldValue('rooms', updatedRooms).catch(captureException)
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={OfferSchema}
    >
      {({ isSubmitting, setFieldValue, values }) => {
        return (
          <Form className='flex h-full flex-col'>
            <div className='flex-1'>
              <div className='bg-gray-50 px-4 py-6 sm:px-6'>
                <div className='flex items-start justify-between space-x-3'>
                  <div className='space-y-1'>
                    <div className='text-base font-semibold leading-6 text-gray-900'>
                      {!editMode ? 'Ajouter une offre' : 'Modifier une offre'}
                    </div>
                  </div>
                </div>
              </div>

              <div className='space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0'>
                <div className='space-y-2 px-4 py-5'>
                  <FormTextField label='Label' name='label' required />
                </div>

                <div className='px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-4'>
                  <FormNumberField label='Prix Expedia' name='externalPrices.expedia' required zeroAllowed />

                  <FormNumberField label='Prix Booking' name='externalPrices.booking' required zeroAllowed />
                </div>

                <div className=''>
                  {bookingRequest.roomsPaxes.map((room, index) => (
                    <div className='my-10' key={index}>

                      <div className='flex items-center justify-between space-x-2 px-4 py-3 bg-slate-400' key={index}>
                        <div className='font-medium'>
                          {`Room ${index + 1}`}
                        </div>

                        <div className='space-x-2'>
                          {room.paxes.map((pax, paxIndex) => (
                            <Badge key={paxIndex}
                              text={`${pax.type} ${pax.age ? ` - ${pax.age} an${pax.age > 1 ? 's' : ''}` : ''}`}
                            />
                          ))}
                        </div>
                      </div>

                      {values.rooms[index].room && (
                        <div className='flex justify-between bg-slate-300 px-4 py-2'>
                          <div className=''>
                            {values.rooms[index].room?.description.en}
                          </div>

                          <IconButton
                            icon={TrashIcon}
                            onClick={() => {
                              handleRemoveRoom(index, setFieldValue, values)
                            }}
                          />
                        </div>
                      )}

                      {values.rooms[index]?.rates.map((rate, rateIndex) => (
                        <div className='border p-4' key={rateIndex}>
                          <div className='flex justify-between'>
                            <p className='font-bold'>
                              {rate.boardName}
                            </p>

                            <IconButton
                              icon={TrashIcon}
                              onClick={() => {
                                handleRemoveRate(index, rateIndex, setFieldValue, values)
                              }}
                            />
                          </div>

                          <div className='py-5 grid grid-cols-4 gap-4'>
                            <div className='flex flex-col text-gray-900 gap-2'>
                              <div className='block font-medium text-sm'>Prix d'achat</div>

                              <div
                                className='rounded-lg text-gray-700 text-center border border-gray-300 bg-gray-50 appearance-none px-3 py-1.5 placeholder-gray-500 focus:outline-none'
                              >
                                {`${rate.net} €`}
                              </div>
                            </div>

                            <FormNumberField
                              label='Marge minimum'
                              name={`rooms[${index}].rates[${rateIndex}].minimumMargin`}
                              onChange={handleMinimumMarginChange(setFieldValue, values, index, rateIndex)}
                              required
                              unitComponent={(
                                <div
                                  className='border border-gray-300 py-1.5 px-3 text-left text-gray-500 bg-gray-100 border-l-0 rounded-r-md'
                                >
                                  {'€'}
                                </div>
                              )}
                              zeroAllowed
                            />

                            <FormNumberField
                              label='Marge'
                              name={`rooms[${index}].rates[${rateIndex}].margin`}
                              onChange={handleMarginChange(setFieldValue, values, index, rateIndex)}
                              unitComponent={(
                                <div
                                  className='border border-gray-300 py-1.5 px-3 text-left text-gray-500 bg-gray-100 border-l-0 rounded-r-md'
                                >
                                  {'%'}
                                </div>
                              )}
                              zeroAllowed
                            />

                            <FormNumberField
                              label='Prix de vente'
                              name={`rooms[${index}].rates[${rateIndex}].sellingPrice`}
                              onChange={handleSellingPriceChange(setFieldValue, values, index, rateIndex)}
                              required
                              unitComponent={(
                                <div
                                  className='border border-gray-300 py-1.5 px-3 text-left text-gray-500 bg-gray-100 border-l-0 rounded-r-md'
                                >
                                  {'€'}
                                </div>
                              )}
                              zeroAllowed
                            />
                          </div>
                        </div>
                      ))}

                      <div className='mt-4 mb-4 px-4'>
                        <Button
                          icon={PlusIcon}
                          onClick={() => {
                            handleOpenAvailabilitiesModal(index + 1)
                          }}
                          size='small'
                          style='secondary'
                          type='button'
                        >
                          Ajouter une option
                        </Button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className='flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6'>
              <div className='flex justify-end space-x-3'>
                <Button
                  isLoading={isSubmitting}
                  type='submit'
                >
                  {!editMode ? 'Ajouter' : 'Modifier'}
                </Button>
              </div>
            </div>

            <AvailabilitiesModal
              allowSelectHotel={false}
              bookingRequest={bookingRequest}
              onAddRate={(rate, room) => {
                handleAddRate(rate, room, setFieldValue, values)
              }}
              open={availabilitiesModalOpen}
              roomNumber={selectedRoomNumber}
              selectedRoom={values.rooms[selectedRoomNumber - 1]?.room}
              setOpen={setAvailabilitiesModalOpen}
            />
          </Form>
        )
      }}
    </Formik>
  )
}

export default OfferCreateForm
